@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('../assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}
